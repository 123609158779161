import React, { useEffect, useState } from "react";
import { Icons } from "../assets/icons";
import { useNavigate } from "react-router-dom";
import { usePusher } from "../helper/usePusher";
import
{
  isVlektra,
  sendEventToAndroid,
  sendEventToiOS,
  vlektraBackgroundColor,
} from "../helper";
function Vehicle()
{
  const queryParams = new URLSearchParams(window.location.search);
  const vtype = queryParams.get("vtype");
  const cid = queryParams.get("cid");
  const pusher = queryParams.get("pusher");

  const [address, setAddress] = useState("")
  const { data } = usePusher(
    pusher ? JSON.parse(pusher) : null,
    null,
    "v1"
  );

  const getDataFromMobile = (data) =>
  {
    if (data?.detail?.data)
      setAddress(data?.detail?.data ?? "")
  }
  useEffect(() =>
  {
    window.addEventListener("onChangedPusher", getDataFromMobile);

    return () =>
    {
      window.removeEventListener("onChangedPusher", getDataFromMobile);

    }

  }, [])


  let navigate = useNavigate();


  // const getGeocodeData = (latitude, longitude, pusherData) =>
  // {
  //   if (count < 4)
  //   {
  //     const apiKey = "$2a$10$GaR4ioDvpMSB5lkpKpXOgernA42HfUYuP0eAmGGth0fk799zIpX2C"
  //     count++
  //     const url = `https://api1.tplmaps.com:8888/search/rgeocode?apikey=${apiKey}&point=${Number(latitude)};${Number(longitude)}`;
  //     fetch(url)
  //       .then((response) => response.json())
  //       .then((googleData) =>
  //       {
  //         setData({
  //           ...pusherData,
  //           address: googleData[0]?.compound_address_parents || "",
  //         });
  //         // Do something with the response data
  //       })
  //       .catch((error) =>
  //       {
  //         // Handle any errors that occur during the fetch request
  //         console.error(error);
  //       });
  //   }
  // };
  // useEffect(() =>
  // {
  //   if (data?.latitude && data?.longitude)
  //     getGeocodeData(Number(data?.latitude), Number(data?.longitude));
  // }, []);

  useEffect(() =>
  {
    if (sendEventToAndroid("onHomeScreen"))
    {
      window.Android.onHomeScreen(String("onHomeScreen"));
    } else if (sendEventToiOS("onHomeScreen"))
    {
      window.webkit.messageHandlers.onHomeScreen.postMessage("onHomeScreen");
    }
  }, []);



  const OnClickfindMyBike = () =>
  {
    if (sendEventToAndroid("eventFindMyBike"))
    {
      window.Android.eventFindMyBike(
        String(
          `https://${window.location.host}/ChargingStation?vtype=${vtype}&lat=${data.latitude}&lng=${data.longitude}&cid=${cid}`
        ),
        String(`Find My ${isVlektra(vtype) ? "Motorcycle" : vtype === "sazgar-sazgar" ? "Sazgar" : "Ecodost"}`)
      );
    } else if (sendEventToiOS("eventFindMyBike"))
    {
      window.webkit.messageHandlers.eventFindMyBike.postMessage(
        String(
          `https://${window.location.host}/ChargingStation?vtype=${vtype}&lat=${data.latitude}&lng=${data.longitude}&cid=${cid}`
        ),
        String(`Find My ${isVlektra(vtype) ? "Motorcycle" : vtype === "sazgar-sazgar" ? "Sazgar" : "Ecodost"}`)

      );
    } else
    {
      navigate(
        `/ChargingStation?vtype=${vtype}&lat=${data.latitude}&lng=${data.longitude}`
      );
    }
  };
  let bg = {
    backgroundColor: isVlektra(vtype) ? vlektraBackgroundColor : "#ddf3f1",
  };

  const onClickBatter = () =>
  {
    if (sendEventToAndroid("eventBatteryManagementScreen"))
    {
      window.Android.eventBatteryManagementScreen(
        String(
          `https://${window.location.host}/BatteryManagement?vtype=${vtype}&cid=${cid}`
        ),
        String("Battery Management")
      );
    } else if (sendEventToiOS("eventBatteryManagementScreen"))
    {
      window.webkit.messageHandlers.eventBatteryManagementScreen.postMessage(
        String(
          `https://${window.location.host}/BatteryManagement?vtype=${vtype}&cid=${cid}`
        ),
        String("Battery Management")
      );
    } else
    {
      navigate(`/BatteryManagement?vtype=${vtype}&cid=${cid}`);
    }
  }
  // const OldBattery = () =>
  // {
  //   return (
  //     <div
  //       className="battery_wrapper"
  //       onClick={() =>
  //       {
  //         onClickBatter()
  //       }}
  //     >
  //       <div style={{ letterSpacing: 2 }} className="battery_title">BATTERY</div>
  //       <div className="battery_percentage">{data?.soc}%</div>
  //       <div className="trapezoid" style={{
  //         borderBottom: `20px solid ${isVlektra(vtype) ? "#505050" : "#1CADA1"}`
  //       }}>
  //         <div>
  //           <div
  //             className="battery_filled"
  //             style={{
  //               width: data?.soc + "%",
  //               background: isVlektra(vtype) ? "#f0c900" : "#1CADA1"
  //             }}
  //           ></div>
  //           <div className="battery-tick-container">
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //             <div className="battery-tick"></div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>

  //   )
  // }
  const NewBattery = () =>
  {
    return (
      <div>
        <div
          className="battery_wrapper"
          onClick={() =>
          {
            onClickBatter()
          }}
        >
          <div className="battery_title">BATTERY</div>
          <div className="battery_percentage">{data?.soc}%</div>
          <div className="trapezoid" >
            <div>
              <div
                className="battery_filled"
                style={{
                  width: data?.soc + "%",
                  background: isVlektra(vtype) ? "#f0c900" : "#1CADA1",
                }}
              ></div>
              <div className="battery-tick-container">
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
              </div>

            </div>
          </div>

        </div>

        <div
          className="battery_wrapper bottom-battery-wrapper"
          onClick={() =>
          {
            onClickBatter()
          }}
        >

          <div className="trapezoid bottom-battery" style={{
            borderBottom: `20px solid ${isVlektra(vtype) ? "#505050" : "#1CADA1"}`
          }}>
            <div>
              <div
                className="battery_filled"
                style={{
                  width: data?.soc + "%",
                  background: isVlektra(vtype) ? "#f0c900" : "#1CADA1"
                }}
              ></div>
              <div className="battery-tick-container">
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
                <div className="battery-tick"></div>
              </div>

            </div>
          </div>

        </div>
      </div>
    )
  }

  return (
    <div className="container_wrapper">
      {
        data == null &&
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={isVlektra(vtype) ? Icons.loader : vtype === "sazgar-sazgar" ? Icons.sazgar_loader : Icons.ecodost_loader}
              className="overlay__content" width={"200px"} alt="missing graphics"></img>
          </div>
        </div>}
      <div className="vehicle_stats_wrapper">
        <div
          className="vehicle_stats"
          style={{
            ...bg,
          }}
        >
          <div className="vehicle_status_wrapper">
            <div>VEHICLE STATUS</div>
            <div>{data?.vehicle_status}</div>
          </div>
          {/* <div style={{ paddingLeft: 10 }}>{">"}</div> */}
        </div>
        <div
          className="emissions_savings_wrapper"
          style={{
            ...bg,
          }}
          onClick={() =>
          {
            if (sendEventToAndroid("eventEmissionScreen"))
            {
              window.Android.eventEmissionScreen(
                String(
                  `https://${window.location.host}/Emission?vtype=${vtype}&cid=${cid}`
                ),
                String("Carbon Savings")
              );
            } else if (sendEventToiOS("eventEmissionScreen"))
            {
              window.webkit.messageHandlers.eventEmissionScreen.postMessage(
                String(
                  `https://${window.location.host}/Emission?vtype=${vtype}&cid=${cid}`
                ),
                String("Carbon Savings")
              );
            } else
            {
              navigate(`/Emission?vtype=${vtype}&cid=${cid}`);
            }
          }}
        >
          CARBON SAVINGS
          <div>{">"}</div>
        </div>
      </div>

      <div style={{ display: "flex", justifyContent: "center" }} >
        <img
          src={Icons[vtype] || Icons.bike_placeholder}
          width={"80%"}
          alt={"car-logo"}
        />
      </div>

      <div className="location_wrapper">
        <img src={Icons.vector} width={"5%"} alt={"location_icon"} />
        <div>{address || ""}</div>
      </div>

      {/* <OldBattery /> */}

      <NewBattery />

      <div className="eco_dost_container">
        <div className="find_ecodost" onClick={OnClickfindMyBike}>
          <div>
            FIND MY {isVlektra(vtype) ? "MOTORCYCLE" : vtype === "sazgar-sazgar" ? "SAZGAR" : "ECODOST"}
          </div>
          <img
            src={isVlektra(vtype) ? Icons.vehicleYellow : Icons.vehicle}
            width={"50px"}
            className="fuel_icon"
            alt={"vehicle-logo"}
          />
        </div>
        <div className="remaining_container">
          <div>CURRENT RANGE</div>
          <div className="row-flex-center">
            <div className="kiloneter_value">{data?.remaining_distance || 0}</div>
            <div className="kiloneter_container">km</div>
          </div>
        </div>
      </div>

      <div className="eco_dost_container">
        <div className="find_ecodost">
          <div>TOTAL DISTANCE</div>
          <div className="kiloneter_value">{`${(data?.total_distance_travelled && data?.total_distance_travelled.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || 0}kms`}</div>
        </div>
        <div className="remaining_container">
          <div>TODAY's DISTANCE</div>
          <div className="row-flex-center">
            <div className="kiloneter_value">{`${(data?.daily_distance && data?.daily_distance.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || 0
              }kms`}</div>
          </div>
        </div>
      </div>

      <div className="container_vehicle">
        <img src={Icons.subtract} alt="fuel-logo" />
        <div className="box_vehicle">
          <div>
            <div>ECONOMY</div>
            <div>{data?.fuel_economy} Rs/km</div>
          </div>
        </div>
      </div>

      {
        (isVlektra(vtype) || vtype === "sazgar-sazgar") &&
        <div className="container_vehicle margin-bottom">
          <img src={Icons.saving} alt="fuel-logo" />
          <div className="box_vehicle">
            <div>
              <div>FUEL COST SAVINGS</div>
              <div>Rs {(data?.total_fuel_savings && Math.round(data?.total_fuel_savings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")) || 0}</div>
            </div>
          </div>
        </div>}


    </div>
  );
}
export default Vehicle