export const Icons = {
  fuel: "https://orko-prod.s3.amazonaws.com/meta/images/ecodost/map/chargers_point.png",
  fuelYellow: "https://orko-prod.s3.amazonaws.com/meta/images/vlektra/map/yellow_theme_chargers_point.png",
  subtract: require("./subtract.png"),
  vector: require("./vector.png"),
  vehicle: require("./vehicle.png"),
  vehicleYellow: require("./vehicle_yellow.png"),
  battery: require("./battery.png"),
  location: require("./location.png"),
  wheeler: require("./wheeler.png"),
  bike_placeholder: require("./bike_placeholder.png"),
  "vlektra-retro": "https://orko-prod.s3.amazonaws.com/md_models/16/1683875628.png",
  "vlektra-bolt": "https://orko-prod.s3.amazonaws.com/md_models/17/1683875645.png",
  "vlektra-retro1969": "https://orko-prod.s3.amazonaws.com/md_models/16/1683875628.png",
  "nova_mobility-ecodost_electric_3_wheeler": "https://orko-prod.s3.amazonaws.com/md_models/22/1679394593.png",
  "icon-vlektra-retro": require("./vlektra.png"),
  "icon-vlektra-bolt": require("./vlektra-bolt-icon.png"),
  "icon-nova_mobility-ecodost_electric_3_wheeler": require("./icon-ecodost.png"),
  "sazgar-sazgar": "https://orko-prod.s3.amazonaws.com/md_models/24/1684749288.png",
  "icon-sazgar-sazgar": "https://orko-prod.s3.amazonaws.com/md_models/24/1684749288.png",
  "arrow-left": require("./arrow-left.png"),
  saving: require("./saving.png"),
  tree: require("./tree.png"),
  co2: require("./co2.png"),
  loader: require("./loader.gif"),
  ecodost_loader: require("./Ecodost.gif"),
  sazgar_loader: require("./sazgarloader.gif"),
  currentLocation: require("./download.png"),

};
