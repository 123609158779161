import React from "react";
import { getBatteryColor, isVlektra, vlektraBackgroundColor } from "../helper";
import { usePusher } from "../helper/usePusher";
import { Icons } from "../assets/icons";
function Vehicle()
{

  const queryParams = new URLSearchParams(window.location.search);
  const vtype = queryParams.get("vtype");
  const pusher = queryParams.get("pusher");

  let bg = {
    backgroundColor: isVlektra(vtype) ? vlektraBackgroundColor : "#ddf3f1",
  };

  const { data, } = usePusher(
    pusher ? JSON.parse(pusher) :
      {
        remaining_distance: "0",
        soc: 0,
        batteries: {},
      }, null, "v1");


  function Box({ title, value })
  {
    return (
      <div className="box"
        style={{
          ...bg,
        }}
      >
        <div className="box-title">{title}</div>
        <div className="box-value">{value}</div>
      </div>
    );
  }

  const BatteryUI = ({ index, charge, length }) =>
  {
    return (
      <div key={"battery" + index}>
        {Number(length) > 1 && <div className="slot-number">Slot#{index + 1}</div>}
        <div className="charge-level">Charge Level</div>
        <div className="charge-level-value">{charge}%</div>
        <center>
          <div className="battery-container">
            <div
              className="battery-filled"
              style={{
                backgroundColor: getBatteryColor(charge, isVlektra(vtype)),
                width: `calc(${charge}% - ${charge > 10 ? "15px" : "0px"})`,
              }}
            ></div>
            <div className="battery-image"></div>
            <div className="cloud"></div>
          </div>
        </center>
      </div>
    );
  };
  return (
    <div className="container_wrapper container_wrapper_battery">

      {
        data?.remaining_distance === "0" &&
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={isVlektra(vtype) ? Icons.loader : vtype === "sazgar-sazgar" ? Icons.sazgar_loader : Icons.ecodost_loader}
              alt="missing graphics" className="overlay__content" width={"200px"}></img>
          </div>
        </div>}

      <div className="title_battery_level">Battery Level</div>
      <div className={"stats_wrapper"}>
        <Box
          title={"REMAINING RANGE"}
          value={data?.remaining_distance + "km"}
        />

        <Box title={"STATE OF CHARGE"} value={data?.soc + "%"} />
      </div>
      <div className="charge-level-wrapper">
        {data?.batteries?.soc?.length ? (
          data?.batteries.soc.map((value, index) =>
          {
            return <BatteryUI index={index} charge={value} type={"multi"} length={data?.batteries?.soc?.length} />;
          })
        ) : (
          <BatteryUI index={0} charge={data?.soc} type={"single"} length={1} />
        )}
      </div>
    </div>
  );
}
export default Vehicle;
