import { isAndroid, isIOS } from "react-device-detect";

export const getBatteryColor = (chargeLevel, isVlektra) =>
{
  if (isVlektra)
  {
    if (chargeLevel > 10 && chargeLevel <= 100)
    {
      return "#F0C900";
    } else
    {
      return "red";
    }
  }
  else
  {
    if (chargeLevel >= 20 && chargeLevel <= 50)
    {
      return "yellow";
    } else if (chargeLevel >= 51 && chargeLevel <= 100)
    {
      return "#1CADA1";
    } else if (chargeLevel >= 0 && chargeLevel <= 19)
    {
      return "red";
    } else
    {
      return "blue";
    }
  }
};

/// handler used in mobile events
export const sendEventToAndroid = (functionType) =>
{
  return isAndroid && window.Android && functionType in window.Android;
};

export const sendEventToiOS = (functionType) =>
{
  return isIOS && functionType in window.webkit.messageHandlers;
};

export const isVlektra = (value) =>
  value === "vlektra-retro" || value === "vlektra-bolt" || value === "vlektra-retro1969" ? true : false;

export const vlektraBackgroundColor = "#FFED92";
