import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import
{
  Vehicle,
  ChargingStation,
  BatteryManagement,
  NotFound,
  Emission,
} from "./modules";
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { withAITracking, ReactPlugin } from '@microsoft/applicationinsights-react-js';

var reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AZURE_INSIGHTS,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  }
});
appInsights.loadAppInsights();
let pages = [
  {
    path: "",
    page: <Vehicle />,
  },
  {
    path: "/ChargingStation",
    page: <ChargingStation />,
  },
  {
    path: "/BatteryManagement",
    page: <BatteryManagement />,
  },
  {
    path: "/Emission",
    page: <Emission />,
  },
  {
    path: "*",
    page: <NotFound />,
  },
];

function App()
{
  const ComponentWithTracking = withAITracking(appInsights, () => (
    <BrowserRouter>
      <Routes>
        {pages.map((values, index) =>
        {
          return (
            <Route
              key={"route" + index}
              exact
              path={values?.path}
              element={values?.page}
            />
          );
        })}
      </Routes>
    </BrowserRouter>
  ));
  return <ComponentWithTracking />;
}


export default App;
