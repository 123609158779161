import Pusher from "pusher-js";
import { useEffect, useState } from "react";

export const usePusher = (initialData, callback) =>
{
  const [data, setData] = useState(initialData);
  useEffect(() =>
  {
    const queryParams = new URLSearchParams(window.location.search);
    const cid = queryParams.get("cid");
    const domain = queryParams.get("domain");

    const pusher = new Pusher(process.env.REACT_APP_PUSHER_ENV, {
      cluster: "ap2",
      authEndpoint: `${process.env.REACT_APP_SERVER}/api/v1/cvp/pusher/auth`,
      auth: {
        headers: {
          "Domain": domain
        }
      }

    });
    //chaneel name
    const channel1 = pusher.subscribe(`private-channel-${cid}`);

    // private channel id
    channel1.bind("vehicle-telematic-data-update", function (pusherData)
    {
      if (callback)
        callback(Number(pusherData?.latitude), Number(pusherData?.longitude), pusherData)
      else setData({
        ...pusherData,
      });
    });

    return () =>
    {
      pusher.unsubscribe(cid);
    };
  }, []);

  return { data, setData };
};
