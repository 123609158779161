/*global google*/
import React, { useEffect, useState, useRef } from "react";
import { Icons } from "../assets/icons";
import axios from "axios";
import { isVlektra, sendEventToAndroid, sendEventToiOS } from "../helper";
import { usePusher } from "../helper/usePusher";
import GoogleMapStyle from "../helper/googleMap.json"
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose"

const GoogleMapComponent = compose(
  withProps({
    googleMapURL: `https://maps.google.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_KEY}`,
    loadingElement: <div style={{ height: `100%`, }} />,
    containerElement: <div style={{ height: `100vh` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) =>
{
  return (<GoogleMap

    ref={(map) => props.setMap(map)}
    defaultZoom={props.options.zoom}
    zoom={props.options.zoom}
    onZoomChanged={(e, y) =>
    {
      props.onZoomChanged()

    }}

    defaultCenter={props.options.center}
    center={props.options.center}
    defaultOptions={{
      styles: GoogleMapStyle, mapTypeControl: false, streetViewControl: false,

    }}
  >
    {props.locations
      &&
      props.locations.length && props.locations.map((marker, i) =>
      {
        if (marker.latitude && marker.longitude)
          return (
            <Marker
              zIndex={marker.primary_key === "vehicle" ? 100 : 3}
              key={i}
              position={{ lat: parseFloat(marker.latitude), lng: parseFloat(marker.longitude) }}
              icon={{
                url: marker.src,
                scaledSize: marker.primary_key === "vehicle" ? new window.google.maps.Size(70, 70) : new window.google.maps.Size(50, 50),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(0, 0),
              }}


              onClick={() =>
              {
                if (marker.primary_key !== "vehicle" && marker.primary_key !== "user"
                  &&
                  process.env.REACT_APP_TYPE === "oldpilot"
                )
                {
                  if (sendEventToAndroid("eventStationId"))
                  {
                    window.Android.eventStationId(String(marker.primary_key));
                  } else if (sendEventToiOS("eventStationId"))
                  {
                    window.webkit.messageHandlers.eventStationId.postMessage(String(marker.primary_key));
                  }
                }
              }}
            />
          )
      })}

  </GoogleMap>)
}
)





const Map = () =>
{
  const queryParams = new URLSearchParams(window.location.search);
  const googleMap = useRef()

  const vtype = queryParams.get("vtype");
  const token = queryParams.get("token");
  const domain = queryParams.get("domain");
  const [markers, setMarkers] = useState([]);
  const [userPosition, setUserPosition] = useState({ lat: 24.8607, lng: 67.0011 });
  const pusher = queryParams.get("pusher");

  const [map, setMap] = useState(null);

  const [zoom, setZoom] = useState(11);

  const { data } = usePusher(
    pusher ? JSON.parse(pusher) :
      {
        latitude: null,
        longitude: null,
      },
    null,
    "v1"
  );
  const setBoundsInMap = (mapMarkers) =>
  {
    if (window?.google?.maps)
    {
      const bounds = new window.google.maps.LatLngBounds();
      mapMarkers.forEach((location, i) =>
      {
        if (i < 4 && location.latitude && location.longitude)
          bounds.extend(
            new window.google.maps.LatLng(
              parseFloat(location.latitude),
              parseFloat(location.longitude)
            ))
        if (map && map?.fitBounds)
        { map.fitBounds(bounds) }
      }
      )
    }
  }
  const [vehiclePacket, setVehiclePacket] = useState(0)
  useEffect(() =>
  {
    if (data?.latitude && data?.longitude && markers[0])
    {
      markers[0] = {
        primary_key: "vehicle",
        latitude: data?.latitude,
        longitude: data?.longitude,
        src: Icons[`icon-${(vtype && vtype != "null") ? vtype : "vlektra-bolt"}`],
      }
      setMarkers([...markers])
      if (markers.length && vehiclePacket === 0)
      {
        setVehiclePacket(1)
        setBoundsInMap(markers)
      }
    }

  }
    , [data])

  // for current user location
  const getUserCurrentLocation = (isCurrentLocation = false) =>
  {
    navigator.geolocation.getCurrentPosition(
      (position) =>
      {

        let latLong = {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        }
        if (!isCurrentLocation)
        {
          let temp = [
            {
              primary_key: "vehicle",
              latitude: data?.latitude ?? null,
              longitude: data?.longitude ?? null,
              src: Icons[`icon-${vtype || "vlektra-bolt"}`],
            },
            {
              primary_key: "user",
              src: Icons.location,
              ...latLong
            }]
          setMarkers([...temp]);
          getFuelStations(position.coords.latitude, position.coords.longitude, temp)
          setBoundsInMap(temp)
        }
        else
        {
          setUserPosition({ ...latLong })
          setBoundsInMap([
            {
              primary_key: "user",
              src: Icons.location,
              ...latLong
            }
          ])
        }

      },
      (err) => console.log(err)
    );
  };
  useEffect(() =>
  {
    getUserCurrentLocation();
  }, []);

  let fuelStationPackets = 0
  const getFuelStations = (latitude, longitude, temp) =>
  {
    const headers = {
      Authorization: `Bearer ${token}`,
      Domain: domain,
    };

    const url =
      `${process.env.REACT_APP_SERVER}/api/v1/charging-station/nearest?latitude=${latitude}&longitude=${longitude}&name=${""}&region=${"pk"}&is_nearest=true`;
    axios
      .get(url, { headers })
      .then((response) =>
      {
        // Handle the response data
        if (response?.data?.body?.stations[0])
        {
          let results = response?.data?.body?.stations;
          results.forEach((values) =>
          {
            temp.push({
              primary_key: values?.location_id,
              latitude: values?.location?.lat,
              longitude: values?.location?.long,
              src: isVlektra(vtype) ? Icons.fuelYellow : Icons.fuel,
            });
          });
          setMarkers([...temp]);

          if (temp.length && fuelStationPackets === 0)
          {
            fuelStationPackets++
            setBoundsInMap(temp)
          }
        }
      })
      .catch((error) =>
      {
        // Handle the error
        console.error(error);
      });
  }

  const isLatLong = markers.every(marker => marker?.latitude && marker?.longitude)
  return (
    <div style={{ height: "100vh", width: "100%" }}>
      {
        !isLatLong &&
        <div className="overlay">
          <div className="overlay__inner">
            <img
              src={isVlektra(vtype) ? Icons.loader : vtype === "sazgar-sazgar" ? Icons.sazgar_loader : Icons.ecodost_loader}
              className="overlay__content" width={"200px"} alt="missing graphics"></img>
          </div>
        </div>}
      <div
        onClick={() =>
        {
          if (markers && markers.length)
          {

            let vehicle = markers.find(value => value.primary_key === "vehicle")
            if (vehicle)
            {
              setUserPosition({ ...{ lat: vehicle?.latitude, lng: vehicle?.longitude } })
              setZoom(15)
            }
          }

        }}
        style={{ position: "absolute", top: "78%", zIndex: 1000, right: "10px" }}><img style={{ width: 40, height: 40, }} alt="missing graphics" src={Icons.currentLocation}></img></div>
      <GoogleMapComponent
        id="myMap"
        options={{
          center: userPosition,
          zoom: zoom,
          mapTypeId: 'mystyle',
        }}
        locations={markers}
        onZoomChanged={() =>
        {
          setZoom(map.getZoom())
        }}

        mapRef={googleMap}
        setMap={(a) => setMap(a)}
      />
    </div>
  );
};

export default Map;